<template>
  <div class="card-body d-flex justify-content-center align-items-center position-relative p-0">
    <div :class="[
      'card shadow rounded product-card aspect-1-1 rounded-3 product-photo overflow-hidden rounded-5',
      { pressEffect: isPressing },
    ]" @click="addToOrder">
      <div class="card-body d-flex justify-content-center align-items-center position-relative p-0">
        <img :src="product.photo" class="aspect-1-1" alt="" />
        <div class="product-text m-1 rounded-3">
          <p class="fw-bold text-white text-center mb-0 p-1">
            {{ product.name }}
          </p>
          <h5 class="text-white text-center mb-1">
            {{ removeDecimal(product.sale_price) }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "@vue/reactivity";
import { useStore } from "vuex";
import removeDecimal from "../../composables/useRemoveDecimal";
import { outofstockalert } from "@/composables/useAlert";

export default {
  props: ["product"],
  setup(props) {
    let store = useStore();
    let isPressing = ref(false);
    let addToOrder = () => {
      isPressing.value = true;
      setTimeout((_) => (isPressing.value = false), 500);
      let product = {
        id: props.product.id,
        name: props.product.name,
        left_qty: props.product.left_qty,
        qty: 1,
        sale_price: props.product.sale_price,
        price: props.product.sale_price,
        purchase_total: props.product.purchase_price,
        purchase_price: props.product.purchase_price,
        total: props.product.sale_price * 1,
      };
      if (
        store.state.order.holdOrders.length > 0 &&
        store.state.order.holdOrders.filter(
          (hod) => hod?.order_products.includes(props.product.id) > 0
        )
      ) {
        var currentHoldPro = store.state.order.holdOrders.find((hold) =>
          hold.order_products.find((hl) => hl.id == props.product.id)
        );
        if (currentHoldPro) {
          var realProduct = currentHoldPro.order_products.filter(
            (opd) => opd.id == props.product.id
          );
          if (realProduct?.[0].qty + 1 > props.product.left_qty) {
            outofstockalert();
            return;
          }
        }
      }
      if (
        store.state.order.orders.length > 0 &&
        store.state.order.orders.includes(props.product.id)
      ) {
        var currentOrderPro = store.state.order.orders.find(
          (prod) => prod.id == props.product.id
        );
        if (currentOrderPro.qty + 1 > props.product.left_qty) {
          outofstockalert();
          return;
        }
      }
      let countOrd = store.state.order.orders.find(
        (od) => od.id == props.product.id
      );
      countOrd = countOrd
        ? store.state.order.orders.find((ord) => ord.id == props.product.id)
          ?.qty
        : props.product.count;
      let cot = computed(() => countOrd);

      if (cot.value + 1 > props.product.left_qty) {
        outofstockalert();
        return;
      }

      let existedOrder = store.state.order.orders.find(
        (order) => order.id == props.product.id
      );
      existedOrder
        ? store.dispatch("incOrder", product.id)
        : store.dispatch("addOrder", product);
    };

    return { addToOrder, isPressing, removeDecimal };
  },
};
</script>

<style lang="scss" scoped></style>
