<template>
  <div class="row g-3" v-auto-animate>
    <div v-for="product in products" :key="product.id" class="col-3">
      <Product :product="product" />
    </div>
    <div v-if="products.length < 1">
      <div class="alert alert-primary" role="alert">No Product found</div>
    </div>
  </div>
</template>

<script>
import Product from "./Product";
export default {
  components: { Product },
  props: ["products"],
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

</style>
