<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import router from "./router";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  setup() {
    let store = useStore();
    const authdata = localStorage.getItem("auth");
    if (authdata) {
      console.log("working");
      store.dispatch("setAuth", JSON.parse(authdata));
    }
    onMounted((_) => {
      if (localStorage.getItem("api")) {
        store.dispatch("setCustomerApi", localStorage.getItem("api"));
      }
    });
  },
  components: {},
};
</script>
<style lang="scss"></style>
