export default {
  state: {
    orders: [],
    tmpOrders: [],
    orderNumber: "",
    startPrint: false,
    payment_type: null,
    tax_discount: {
      discount_percent: 0,
      tax: 0,
      discount: 0,
      tax_price: 0,
    },
    total: 0,
    subtotal: 0,
    purchasetotal: 0,
    keyword: "",
    holdOrders: [],
    salePriceModalId: "",
    flat_or_percent: "flat",
  },
  getters: {
    getStartPrint(state) {
      return state.startPrint;
    },
    // search
    getKeyword(state) {
      return state.keyword;
    },
  },
  mutations: {
    SET_START_PRINT: (state, payload) => (state.startPrint = payload),
    SET_ORDER_NUMBER: (state, payload) => (state.orderNumber = payload),
    ADD_TMP_ORDER: (state, payload) => (state.tmpOrders = payload),
    ADD_ORDER: (state, payload) => {
      state.orders.unshift(payload);
      let currentProduct = state.tmpOrders.find(
        (product) => product.id == payload.id
      );
      currentProduct.count += 1;
    },
    // ADD_ORDER: (state, payload) => console.log("order", payload),
    SET_ORDER: (state, payload) => {
      state.orders = payload;
      console.log(payload);
    },
    INC_ORDER: (state, payloadId) => {
      let order = state.orders.find((order) => order.id == payloadId);
      let currentProduct = state.tmpOrders.find(
        (product) => product.id == payloadId
      );
      currentProduct.count += 1;
      order.qty += 1;
      order.purchase_total = order.purchase_price * order.qty;
      order.total = order.price * order.qty;
    },
    DEC_ORDER: (state, payloadId) => {
      let order = state.orders.find((order) => order.id == payloadId);
      order.qty == 1
        ? (state.orders = state.orders.filter(
          (order) => order.id !== payloadId
        ))
        : (order.qty -= 1);
      order.purchase_total = order.purchase_price * order.qty;
      order.total = order.price * order.qty;
    },
    SET_SINGLE_ORDER_QTY: (state, payload) => {
      let selectedOrder = state.orders.find((order) => order.id == payload.id);
      selectedOrder.qty = payload.qty;
      selectedOrder.total = selectedOrder.qty * selectedOrder.sale_price;
    },
    DELETE_SINGLE_ORDER: (state, payloadId) => {
      let tempOrder = (state.tmpOrders.find(
        (order) => order.id == payloadId
      ).count = 0);
      state.orders = state.orders.filter((order) => order.id !== payloadId);
    },
    CLEAR_ORDER: (state) => {
      state.orders = [];
      state.total = 0;
      state.subtotal = 0;
      state.tax_discount.tax = "";
      state.tax_discount.discount = "";
    },
    // search
    SET_KEYWORD: (state, payload) => (state.keyword = payload),
    SET_TAX_DISCOUNT: (state, payload) => {
      state.tax_discount.tax = payload.tax;
      state.tax_discount.discount_percent = payload.discount_percent;
      state.tax_discount.discount = payload.discount;
      state.tax_discount.tax_price = payload.tax_price;
      console.log(payload);
    },

    SET_TOTAL: (state, payload) => (state.total = payload),
    SET_SALE_PRICE_MODAL_ID: (state, payload) => state.salePriceModalId = payload,
    SET_SALE_PRICE: (state, payload) => {
      let order = state.orders.find(ord => ord.id == state.salePriceModalId);
      order.sale_price = payload;
      order.total = order.qty * order.sale_price;
    },
    SET_SUBTOTAL: (state, payload) => (state.subtotal = payload),
    SET_PURCHASE_TOTAL: (state, payload) => (state.purchasetotal = payload),
    SET_HOLD_ORDERS: (state, payload) => {
      state.holdOrders.unshift(payload);
    },
    REMOVE_HOLD_ORDERS_BYIND: (state, payload) =>
    (state.holdOrders = state.holdOrders.filter(
      (ele, ind) => ind !== payload
    )),
    SET_PAYMENT_TYPE: (state, payload) => state.payment_type = payload,
    SET_FLAT_OR_PERCENT: (state, payload) => state.flat_or_percent = payload
  },
  actions: {
    setStartPrint: ({ commit }, payload) => commit("SET_START_PRINT", payload),
    addTmpOrder: ({ commit }, payload) => commit("ADD_TMP_ORDER", payload),
    setOrderNumber: ({ commit }, payload) =>
      commit("SET_ORDER_NUMBER", payload),
    addOrder: ({ commit }, payload) => commit("ADD_ORDER", payload),
    setOrder: ({ commit }, payload) => commit("SET_ORDER", payload),
    incOrder: ({ commit }, payloadId) => commit("INC_ORDER", payloadId),
    decOrder: ({ commit }, payloadId) => commit("DEC_ORDER", payloadId),
    setSingleOrderQty: ({ commit }, payload) =>
      commit("SET_SINGLE_ORDER_QTY", payload),
    clearOrder: ({ commit }) => commit("CLEAR_ORDER"),
    deleteSingleOrder: ({ commit }, payloadId) =>
      commit("DELETE_SINGLE_ORDER", payloadId),
    // search
    setKeyword: ({ commit }, payload) => commit("SET_KEYWORD", payload),

    setTax_Discount: ({ commit }, payload) =>
      commit("SET_TAX_DISCOUNT", payload),
    setTotal: ({ commit }, payload) => commit("SET_TOTAL", payload),
    setSalePrice: ({ commit }, payload) => commit("SET_SALE_PRICE", payload),
    setPurchaseTotal: ({ commit }, payload) =>
      commit("SET_PURCHASE_TOTAL", payload),
    setSubTotal: ({ commit }, payload) => commit("SET_SUBTOTAL", payload),
    setHoldOrders: ({ commit }, payload) => commit("SET_HOLD_ORDERS", payload),
    setSalePriceModalId: ({ commit }, payload) => commit("SET_SALE_PRICE_MODAL_ID", payload),
    removeHoldOrdersByInd: ({ commit }, payload) => commit("REMOVE_HOLD_ORDERS_BYIND", payload),
    setPaymentType: ({ commit }, payload) => commit("SET_PAYMENT_TYPE", payload),
    setFlatOrPercent: ({ commit }, payload) => commit("SET_FLAT_OR_PERCENT", payload)
  },
};
