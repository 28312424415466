<template>
  <div class="row g-1 justify-content-center align-items-center">
    <div class="col-3">
      <p class="my-1 text-truncate text-start">{{ order.name }}</p>
      <small class="small-xs">{{ order.qty }} x {{ removeDecimal(order.sale_price) }}</small>
    </div>
    <div class="col-6">
      <p class="text-center text-nowrap mb-0">
        <button type="button" @click="decreaseOrder(order.id)" class="me-1 btn rounded-pill btn-icon btn-label-danger me-1">
          <i class="bi bi-dash"></i>
        </button>
        <span v-if="!orderEdit" class="me-1 bg-label-primary p-1 px-2 rounded d-inline-block" style="width: 65px; cursor: pointer" @click="changeOrderCount">
          {{ order.qty }}</span>
        <input v-else v-click-away="unsetEditMode" autofocus @keypress.enter="unsetEditMode" type="number" class="form-control d-inline-block" style="width: 90px" placeholder="enter quantity" v-model="order.qty" />
        <button type="button" @click="increaseOrder(order.id)" class="me-1 btn rounded-pill btn-icon btn-label-success">
          <i class="bi bi-plus"></i>
        </button>
        <button type="button" @click="deleteSingleOrder(order.id)" class="me-1 btn rounded-pill btn-icon btn-label-danger">
          <i class="bi bi-trash"></i>
        </button>
        <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#myModal">Launch modal</button> -->

        <button type="button" data-bs-toggle="modal" data-bs-target="#backDropModal" @click="showSetSalePriceModal" class="btn rounded-pill btn-icon btn-label-info">
          <i class="bi bi-cash-coin"></i>
        </button>
      </p>
    </div>
    <div class="col-3">
      <p class="text-end text-nowrap my-1">
        {{ totalCost }}
      </p>
    </div>
  </div>

  <hr class="my-1" />
</template>

<script>
import { confirm } from "@/composables/useConfirm";
import order from "@/store/order";
import { computed } from "@vue/reactivity";
import { ref, watch } from "vue";
import { useStore } from "vuex";
import removeDecimal from "@/composables/useRemoveDecimal";
import { outofstockalert } from "@/composables/useAlert";
export default {
  props: ["order"],
  setup(props) {
    let store = useStore();
    let orderEdit = ref(false);
    let deleteSingleOrder = (payloadId) =>
      confirm("Sure to delete?", "", () =>
        store.dispatch("deleteSingleOrder", payloadId)
      );
    let count = computed(() => {
      return store.state.order.tmpOrders.find((ord) => ord.id == props.order.id)
        .count;
    });

    let increaseOrder = (payloadId) => {
      if (count.value + 1 > props.order.left_qty) {
        outofstockalert();
        return;
      }
      if (store.state.order.orders.length > 0) {
        var currentOrderPro = store.state.order.orders.find(
          (prod) => prod.id == props.order.id
        );
        if (currentOrderPro.qty + 1 > props.order.left_qty) {
          outofstockalert();
          return;
        }
      }
      if (store.state.order.holdOrders.length > 0) {
        var currentHoldPro = store.state.order.holdOrders.find((hold) =>
          hold.order_products.find((hl) => hl.id == props.product.id)
        );
        var realProduct = currentHoldPro.order_products.filter(
          (opd) => opd.id == props.order.id
        );
        if (realProduct?.[0].qty + 1 > props.order.left_qty) {
          outofstockalert();
          return;
        }
      }
      store.dispatch("incOrder", payloadId);
    };
    let decreaseOrder = (payloadId) => store.dispatch("decOrder", payloadId);
    let totalCost = computed(() => props.order.qty * props.order.sale_price);

    let changeOrderCount = (_) => (orderEdit.value = true);

    let unsetEditMode = (_) => {
      orderEdit.value = false;
      props.order.qty < 1 &&
        store.dispatch("deleteSingleOrder", props.order.id);
    };

    let showSetSalePriceModal = _ => store.dispatch("setSalePriceModalId", props.order.id);

    let setSalePrice = id => store.dispatch("setSalePrice", id);

    watch(totalCost, (_) => {
      store.dispatch("setSingleOrderQty", {
        id: props.order.id,
        qty: props.order.qty,
      });
    });

    return {
      increaseOrder,
      decreaseOrder,
      deleteSingleOrder,
      changeOrderCount,
      orderEdit,
      setSalePrice,
      showSetSalePriceModal,
      totalCost,
      unsetEditMode,
      removeDecimal,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
