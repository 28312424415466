<template>
  <Layout>
    <div class="col-2 custom-scroll bg-custom rounded">
      <CategoryList @categoryChange="changeCategory" />
    </div>
    <div class="col-5 pb-3 custom-scroll">
      <span class="badge rounded-pill bg-label-primary mb-3" v-if="searchKeyword">
        Search result for : {{ searchKeyword }}
      </span>
      <ProductList :products="products" v-if="!isCategoryLoading" />
      <div v-else class="w-100 vh-60 d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    </div>
    <div class="col-5 ps-0 position-relative custom-scroll">
      <Orders />
    </div>
    <SalePriceModal />
  </Layout>
</template>

<script>
import Orders from "../components/Home/Orders";
import Loader from "../components/Misc/Loader.vue";
import CategoryList from "../components/Home/CategoryList";
import ProductList from "../components/Home/ProductList.vue";
import SalePriceModal from "@/components/Home/SalePriceModal.vue";
import Layout from "@/components/layout/Layout.vue";
import axios from "axios";

import { onMounted, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import { useWebNotification } from "@vueuse/core";
export default {
  components: {
    Orders,
    Loader,
    CategoryList,
    Layout,
    ProductList,
    SalePriceModal,
  },
  setup() {
    let token = useStore().state.auth.token;
    let store = useStore();
    let products = ref([]);
    let currentCategory = ref("all");
    let isCategoryLoading = ref(false);
    let searchKeyword = ref("");

    let changeCategory = (payload) => (currentCategory.value = payload);

    let fetchProducts = async (id) => {
      if (id == "all") {
        isCategoryLoading.value = true;
        let response = await axios.get(
          store.getters.getCustomerApi.allProductApi,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let manipulated = response.data.data.map((product) => {
          return {
            id: product.id,
            name: product.name,
            sale_price: product.sale_price,
            purchase_price: product.purchase_price,
            left_qty: product.left_qty,
            count: 0,
            isNew: false,
            photo: product.photo?.original_url
              ? product.photo.original_url
              : "/img/imgnotfound.png", //0 array coz photo and product is one to many
          };
        });
        products.value = manipulated;
        store.dispatch("addTmpOrder", manipulated);
        isCategoryLoading.value = false;
      } else {
        isCategoryLoading.value = true;
        let response = await axios.get(
          `${store.getters.getCustomerApi.productsByCategory}/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let manipulated = response.data.data.product.map((product) => {
          return {
            id: product.id,
            name: product.name,
            sale_price: product.product_warehouse.sale_price,
            purchase_price: product.product_warehouse.purchase_price,
            left_qty: product.product_warehouse.left,
            count: 0,
            isNew: false,
            photo: product.photo?.original_url
              ? product.photo.original_url
              : "/img/imgnotfound.png", //0 array coz photo and product is one to many
          };
        });
        products.value = manipulated;
        store.dispatch("addTmpOrder", manipulated);
        isCategoryLoading.value = false;
      }
    };

    watch(currentCategory, () => fetchProducts(currentCategory.value));

    // search
    watchEffect((_) => {
      const keyword = store.getters.getKeyword;
      if (keyword !== "") {
        products.value = computed((_) =>
          store.state.order.tmpOrders.filter((tmpOrder) =>
            tmpOrder.name.toLowerCase().includes(keyword.toLowerCase())
          )
        ).value;
        searchKeyword.value = keyword;
      } else {
        fetchProducts(currentCategory.value);
        searchKeyword.value = "";
      }
    });
    onMounted(() => {
      fetchProducts(currentCategory.value);

      window.Echo.channel("product").listen("ProductStored", (payload) => {
        let manipulated = {
          id: payload.product.id,
          name: payload.product.name,
          sale_price: payload.product.sale_price,
          isNew: true,
        };

        products.value.push(manipulated);
        let { isSupported, show, notification } = useWebNotification({
          title: "New Product is arrived",
          icon: window.location.origin + "/android-chrome-192x192.png",
        });
        isSupported.value && show();
      });
    });
    return { changeCategory, products, isCategoryLoading, searchKeyword };
  },
};
</script>

<style lang="scss" scoped></style>
