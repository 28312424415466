export default {
    state: {
        current: "voucher1",
        customer: {
            name: "aa",
            phone: ""
        }
    },
    getters: {
        getCurrent(state) {
            return state.current;
        },
        getCustomerInfo(state) {
            return state.customer;
        }
    },
    mutations: {
        SET_CURRENT: (state, payload) => state.current = payload,
        SET_CUSTOMER: (state, payload) => {
            state.customer.name = payload.name;
            state.customer.phone = payload.phone
        }
    },
    actions: {
        setCurrent: ({ commit }, payload) => commit("SET_CURRENT", payload),
        setCustomer: ({ commit }, payload) => commit("SET_CUSTOMER", payload)
    },
};
