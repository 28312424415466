<template>
    <div class="modal fade " id="backDropModal" tabindex="-1">
        <div class="modal-dialog mt-vh-5">
            <form class="modal-content" @submit.prevent="updateSalePrice">
                <div class="modal-header">
                    <h5 class="modal-title" id="backDropModalTitle">Change Sale Price</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col mb-3">
                            <label for="nameBackdrop" class="form-label">Price</label>
                            <input type="text" id="nameBackdrop" class="form-control" v-model="price" placeholder="Enter Price" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-primary" id="closeModal" data-bs-dismiss="modal" @click="updateSalePrice">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

let store = useStore();
let price = ref("");

let updateSalePrice = _ => {
    document.querySelector("#closeModal").click();
    store.dispatch("setSalePrice", price.value);
}

</script>

<style scoped>

</style>